import { IPackJson } from "@pro/common/conf";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { app } from "../../App";
import { BuyHeroPackAction } from "../../eos/actions/BuyHeroPackAction";
import { L } from "../../l10n/L10n";
import { Spacer } from "../Spacer";
import BalanceResource from "./BalanceResource";
import { GameButton, GameButtonStyleType } from "./GameButton";
import Styles from "./HeroPackView.module.scss";
import { PageTitle } from "./PageTitle";

const CardInfoLine = ({title, value}: {title: string, value: number}) => (
	<div className={Styles.styleHeroPackCardInfoLineContainer}>
		<div className={Styles.styleHeroPackCardInfoKey}>
			{title}
		</div>
		<div className={Styles.styleHeroPackCardInfoValue}>
			{`${value}%`}
		</div>
	</div>
);

export const HeroPackView = () => {
	const [btnReady, setBtnReady] = useState(true);

	const isMediumScreen = useMediaQuery({query: "(min-width: 768px)"});
	const rarityPercents = app.assetConf.findByAttribId<IPackJson>("p5")?.rarity_percent ?? [52, 24, 11, 8, 4, 1];

	function onBtnClick()
	{
		setBtnReady(false);
		new BuyHeroPackAction()
			.setReadyStateAction(setBtnReady)
			.onError(() => setBtnReady(true))
			.executeAsync();
	}

	return (
		<div className={Styles.styleHeroPackContainer}>
			<div className={Styles.styleHeroPackImage}>
				<PageTitle title={L.gameMarket.packTitle}/>
				<div className={Styles.styleHeroPackSummaryContainer}>
					<div className={Styles.styleHeroPackSummary}>
						<CardInfoLine title={L.rarity.common} value={rarityPercents[0]}/>
						<CardInfoLine title={L.rarity.rare} value={rarityPercents[1]}/>
						<CardInfoLine title={L.rarity.unique} value={rarityPercents[2]}/>
						<CardInfoLine title={L.rarity.epic} value={rarityPercents[3]}/>
						<CardInfoLine title={L.rarity.legendary} value={rarityPercents[4]}/>
						<CardInfoLine title={L.rarity.mythic} value={rarityPercents[5]}/>
					</div>
					<div className={Styles.styleHeroPackFooter}>

						<BalanceResource invertColor={isMediumScreen} forceBalance={app.chainConf.HERO_PACK_PRICE}/>
						<Spacer size={20}/>
						<GameButton text={L.gameMarket.buy} style={GameButtonStyleType.MIDDLE} onClick={onBtnClick}
						            ready={btnReady}/>
					</div>
				</div>
			</div>
		</div>
	);
};
