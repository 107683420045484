import { observer } from "mobx-react";
import React from "react";
import { CardView } from "../../components/game/CardView";
import GameLayout from "../../components/game/GameLayout";
import { HeroPackView } from "../../components/game/HeroPackView";
import { useLoadGameData } from "../../hooks/useLoadGameData";
import { L } from "../../l10n/L10n";
import Styles from "./market.module.scss";

const GameHomePage = observer(() => {
	useLoadGameData();

	return (
		<GameLayout title={L.gameMarket.title} description={L.gameMarket.desc}>
			<div className={Styles.stylePageContainer}>
				<CardView/>
				<HeroPackView/>
			</div>
		</GameLayout>
	);
});
export default GameHomePage;
