import { EosAsset } from "@pro/common/eos";
import { app, eos, world } from "../../App";
import { EosActionBase } from "../EosActionBase";

export class BuyHeroPackAction extends EosActionBase
{
	constructor()
	{
		super("buy_hero_pack");
	}

	async onExecute()
	{
		await eos.transferToken(
			app.chainConf.MN_ACCOUNT,
			app.chainConf.TB_ACCOUNT,
			new EosAsset(app.chainConf.HERO_PACK_PRICE, app.chainConf.MANA_TOKEN),
			""
		)
	}

	async onSelfUpdate()
	{
		await world.updateAssets();
		await world.mustUpdateBalance(app.chainConf.MN_ACCOUNT, app.chainConf.MANA_TOKEN);
	}
}